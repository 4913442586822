<script lang="ts">
  import { cn } from "#lib/utils"
  import type { SVGComponent } from "@tastyworks/icons"
  import { alertIconVariants, renderIconByVariant } from "."
  import { getAlertRootContext } from "./AlertRoot.svelte"

  export let icon: SVGComponent | undefined = undefined

  let cssClass: string | undefined | null = undefined
  export { cssClass as class }

  const rootCtx = getAlertRootContext()
</script>

<svelte:component
  this={icon ?? renderIconByVariant($rootCtx.variant)}
  class={cn(alertIconVariants({ variant: $rootCtx.variant }), cssClass)}
/>
