<script lang="ts">
  import { cn } from "#lib/utils"
  import type { HTMLAttributes } from "svelte/elements"

  type $$Props = HTMLAttributes<HTMLDivElement>

  let className: $$Props["class"] = undefined
  export { className as class }
</script>

<div
  class={cn(
    "rounded-md border border-general-main-panel bg-general-primary-surface shadow",
    className
  )}
  {...$$restProps}
  on:click
>
  <slot />
</div>
