<script lang="ts">
  import { getUiclContext, useActions, type HTMLActionArray } from "#lib"
  import type { BaseProps } from "#lib/internal/types"
  import { cn } from "#lib/utils"
  import type { VariantProps } from "class-variance-authority"
  import type { ClassValue } from "class-variance-authority/types"
  import type {
    HTMLAnchorAttributes,
    HTMLButtonAttributes,
  } from "svelte/elements"
  import { buttonVariants } from "."

  let className: ClassValue | undefined | null = undefined
  export { className as class }
  export let href: HTMLAnchorAttributes["href"] = undefined
  export let type: HTMLButtonAttributes["type"] = "button"
  export let variant: VariantProps<typeof buttonVariants>["variant"] = "default"
  export let ref: HTMLAnchorElement | HTMLButtonElement | null = null
  /**
   * default to _blank if href is truthy
   */
  export let target: HTMLAnchorAttributes["target"] = href
    ? "_blank"
    : undefined

  type $$Props = Omit<BaseProps<"button"> & BaseProps<"a">, "class"> & {
    class?: ClassValue | null
    variant?: VariantProps<typeof buttonVariants>["variant"]
    use?: HTMLActionArray
    ref?: HTMLAnchorElement | HTMLButtonElement | null
  }

  const v2 = !!getUiclContext()?.v2
</script>

<svelte:element
  this={href ? "a" : "button"}
  type={href ? undefined : type}
  {href}
  class={cn(buttonVariants({ variant, v2, className }))}
  use:useActions={$$restProps.use}
  {...$$restProps}
  role={href ? "link" : "button"}
  bind:this={ref}
  {target}
  on:click
  on:change
  on:keydown
  on:keyup
  on:mouseenter
  on:mouseleave
  data-variant={variant}
>
  <slot />
</svelte:element>
