<script context="module" lang="ts">
  import { reactiveContext } from "#lib/internal/helpers"

  const { getContext, setContext } = reactiveContext({
    variant: null as VariantProps<typeof alertVariants>["variant"],
  })

  export const getAlertRootContext = getContext
</script>

<script lang="ts">
  import type { BaseProps } from "#lib/internal/types"
  import { cn } from "#lib/utils"
  import Icons from "@tastyworks/icons"
  import type { VariantProps } from "class-variance-authority"
  import { createEventDispatcher } from "svelte"
  import { Alert, alertVariants } from "."

  type $$Props = BaseProps<"div"> & {
    class?: string
    dismissible?: boolean
    variant: VariantProps<typeof alertVariants>["variant"]
    size?: VariantProps<typeof alertVariants>["size"]
  }

  const dispatch = createEventDispatcher()
  let className: string | undefined | null = undefined
  export { className as class }
  export let dismissible = false
  export let variant: VariantProps<typeof alertVariants>["variant"]
  export let size: VariantProps<typeof alertVariants>["size"] = "full"
  let show = true

  const rootCtx = setContext({
    variant: (v) => (variant = v),
  })
  $: rootCtx.set({ variant })

  function handleDismiss() {
    show = false
    dispatch("dismiss")
  }
</script>

<div
  class={cn(alertVariants({ variant, size, className }))}
  {...$$restProps}
  class:hidden={!show}
  role="alert"
>
  {#if dismissible}
    <Icons.exit
      class="mr-large mt-medium relative float-right ml-4 cursor-pointer text-general-secondary-label font-x-large-600"
      on:click={handleDismiss}
    />
  {/if}

  <div class="flex gap-3">
    <slot name="icon">
      <Alert.Icon />
    </slot>

    <div class="flex flex-col gap-0.5">
      <slot />
    </div>
  </div>
</div>
